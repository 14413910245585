export default function moderationFilters(opts = {}) {
  let timeout;

  const debounce = (fn, ms) => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, ms);
  };

  return {
    showFilters: true,
    openTopics: false,
    filtersApplied: false,
    searchQuery: '',
    publishStatus: opts.publishStatus,
    moderationStatus: opts.moderationStatus,
    topicIds: opts.topicIds,
    topicIdsSelected: opts.topicIds,
    sortBy: opts.sortBy,

    init() {
      this.setPublishStatusFilter(this.publishStatus, true);
      this.setModerationStatusFilter(this.moderationStatus, true);
      this.setTopicsFilter(true);
      this.filtersApplied = this.isFiltersApplied();
      this.$watch('searchQuery', () => this.submitFilters());
    },

    setPublishStatusFilter(status, skipSubmit = false) {
      this.publishStatus = status;
      this.$refs.publishStatus.value = status;
      if (!skipSubmit) this.submitFilters();
    },

    setModerationStatusFilter(status, skipSubmit = false) {
      this.moderationStatus = status;
      this.$refs.moderationStatus.value = status;
      if (!skipSubmit) this.submitFilters();
    },

    setTopicsFilter(skipSubmit = false) {
      let ids = [];
      this.$refs.topicFilters.querySelectorAll('input[data-topic-filter]').forEach((input) => {
        if (input.checked) ids.push(parseInt(input.dataset.topicFilter));
      });
      this.topicIdsSelected = ids;
      this.$refs.checkAllTopics.checked = this.allTopicsSelected();
      if (!skipSubmit) this.submitFilters();
    },

    toggleAllTopics() {
      if (this.allTopicsSelected()) {
        this.topicIdsSelected = [];
        this.uncheckAllTopics();
      } else {
        this.topicIdsSelected = [...this.topicIds];
        this.checkAllTopics();
      }
    },

    allTopicsSelected() {
      return this.topicIds.every((id) => this.topicIdsSelected.includes(id));
    },

    submitFilters() {
      debounce(() => {
        this.$root.requestSubmit();
        this.filtersApplied = this.isFiltersApplied();
      }, 550);
    },

    isFiltersApplied() {
      return this.publishStatus !== 'all' || this.moderationStatus !== 'all' || !this.allTopicsSelected();
    },

    checkAllTopics(skipSubmit = false) {
      this.$refs.topicFilters.querySelectorAll('input').forEach((input) => {
        input.checked = true;
      });
      if (!skipSubmit) this.submitFilters();
    },

    uncheckAllTopics(skipSubmit = false) {
      this.$refs.topicFilters.querySelectorAll('input').forEach((input) => {
        input.checked = false;
      });
      if (!skipSubmit) this.submitFilters();
    },

    resetFilters() {
      this.setPublishStatusFilter('all', true);
      this.setModerationStatusFilter('all', true);
      this.checkAllTopics(true);
      this.setTopicsFilter(true);
      this.submitFilters();
      this.showFilters = true;
      this.openTopics = false;
    },

    removeTopic(topicToRemove) {
      this.$refs.topicFilters.querySelector(`input[data-topic-filter='${topicToRemove}']`).checked = false;
      this.setTopicsFilter();
    },

    clearSearch() {
      this.$refs.search.value = '';
      this.submitFilters();
    },

    reset: {
      ['x-on:click.prevent.stop']() {
        this.resetFilters();
      },
      ['x-show']() {
        return this.filtersApplied;
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-100';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0 scale-95';
      },
      ['x-transition:enter-end']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave']() {
        return 'transition ease-in duration-75';
      },
      ['x-transition:leave-start']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave-end']() {
        return 'transform opacity-0 scale-95';
      },
    },

    filters: {
      ['x-show']() {
        return this.showFilters;
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-100';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0 scale-95';
      },
      ['x-transition:enter-end']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave']() {
        return 'transition ease-in duration-75';
      },
      ['x-transition:leave-start']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave-end']() {
        return 'transform opacity-0 scale-95';
      },
    },
  };
}
